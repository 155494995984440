// src/utils/analytics.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Initialize Google Analytics
export const initializeGA = () => {
  // Replace GA_MEASUREMENT_ID with your actual Google Analytics measurement ID
  window.gtag('config', 'G-TK1DRBX94J', {
    send_page_view: false // Disable automatic page views
  });
};

// Custom hook to track page views and UTM parameters
export const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    const trackPageView = () => {
      // Get UTM parameters from URL
      const searchParams = new URLSearchParams(location.search);
      const utmSource = searchParams.get('utm_source');
      const utmMedium = searchParams.get('utm_medium');
      const utmCampaign = searchParams.get('utm_campaign');

      // Prepare event parameters
      const eventParams = {
        page_path: location.pathname,
        page_location: window.location.href,
        page_title: document.title,
      };

      // Add UTM parameters if they exist
      if (utmSource) eventParams.utm_source = utmSource;
      if (utmMedium) eventParams.utm_medium = utmMedium;
      if (utmCampaign) eventParams.utm_campaign = utmCampaign;

      // Send page view event to Google Analytics
      window.gtag('event', 'page_view', eventParams);

      // If UTM parameters exist, send a separate campaign event
      if (utmSource || utmMedium || utmCampaign) {
        window.gtag('event', 'campaign_visit', {
          utm_source: utmSource || '(not set)',
          utm_medium: utmMedium || '(not set)',
          utm_campaign: utmCampaign || '(not set)',
          page_path: location.pathname
        });
      }
    };

    trackPageView();
  }, [location]);
};
